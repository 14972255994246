import * as Wordsearch from '@blex41/word-search';
import { isEqual } from 'lodash';
import { CSSProperties } from 'react';
import {
  ICell,
  IGrid,
  IWord,
  IWordSearchData
} from 'src/interfaces/IWordSearch';

// sometimes the words and the given secret message don't fit in the grid perfectly.
// So we try to pad it with X's to make it fit.
// Ideally we should rerun to make a better wordsearch than pad too much
const ACCEPTABLE_PADDING_ALLOWED = 4;
const RETRY_ATTEMPTS = 150;
const defaultWordList = [
  'koh',
  'phangan',
  'puzzle',
  'pirate',
  'beach',
  'sand',
  'booty',
  'chest',
  'gold',
  'ship',
  'sail',
  'cannon',
  'parrot',
  'anchor',
  'dagger',
  'island',
  'plank'
];

export function getHighlightlightWidth(start: ICell, end: ICell, cellSize: number): number {
  const horizontal = Math.abs(start.x - end.x);
  const vertical = Math.abs(start.y - end.y);
  let width = Math.sqrt((horizontal ** 2) + (vertical ** 2)) * cellSize;
  if ((start.x !== end.x) || (start.y !== end.y)) {
    width += cellSize;
  }
  return width;
}

export function getHighlightRotation(start: ICell, end: ICell): number {
  const opposite = Math.abs(start.y - end.y);
  const adjacent = Math.abs(start.x - end.x);
  let angle = Math.atan2(adjacent, opposite) * (180 / Math.PI);

  const isForwards = start.x <= end.x;
  const isUpwards = start.y >= end.y;
  // the highlight can be in quadrants:
  // NE  NW
  // SE  SW

  if (isForwards && isUpwards) {
    // quadrant NW
    angle -= 90;
  } else if (isForwards && !isUpwards) {
    // quadrant SW
    angle = 90 - angle;
  } else if (!isForwards && !isUpwards) {
    // quadrant SE
    angle -= 90 * -1;
  } else if (!isForwards && isUpwards) {
    // quadrant NE
    angle = 270 - angle;
  }
  return angle;
}

export function isCellInList(cell: ICell, usedCells: ICell[]): boolean {
  return usedCells.findIndex((usedCell) => (usedCell.x === cell.x) && (usedCell.y === cell.y)) !== -1;
}

function clearUnsedCells(grid: IGrid, usedCells: ICell[]): IGrid {
  return grid.map((row, rowIdx) => row.map((cell, colIdx) => {
    if (isCellInList({ x: colIdx, y: rowIdx }, usedCells)) {
      return cell;
    }
    return '';
  }));
}

function addSecretMessage(grid: IGrid, message: string): IGrid {
  const secretChars = message.split('').filter((char) => char !== ' ');
  return grid.map((row) => row.map((cell) => {
    if (cell === '') {
      return secretChars.shift() || 'X';
    }
    return cell;
  }));
}

export function findUsedCells(words: Wordsearch.IWord[]): ICell[] {
  // find all cells used by words (including duplicates)
  const allUsed = words.reduce((acc, word) => [...acc, ...word.path], []);
  // now remove the duplicates
  return allUsed.filter(
    (cell, idx) => allUsed.findIndex((usedCell) => (usedCell.x === cell.x) && (usedCell.y === cell.y)) === idx
  );
}

function buildWordSearch(
  wordList: string[],
  secretMessage: string,
  size: number
): IWordSearchData|null {
  const options = {
    cols: size,
    rows: size,
    dictionary: wordList,
    disabledDirections: [],
    backwardsProbability: 0.5,
    upperCase: true,
    maxWords: 8,
    diacritics: false
  };
  let secret = secretMessage.replace(/ /g, '');

  const ws = new Wordsearch(options);
  const { grid, words } = ws.data;
  const usedCells = findUsedCells(words);
  const cleanGrid = clearUnsedCells(grid, usedCells);
  const remainingCells = (size * size) - usedCells.length;
  if (remainingCells < secret.length) {
    return null;
  }
  let amountToPad = 0;
  if (secret.length < remainingCells) {
    amountToPad = remainingCells - secret.length;
    const padStart = Math.floor(amountToPad / 2);
    const padEnd = amountToPad - padStart;
    secret = `${'X'.repeat(padStart)}${secret}${'X'.repeat(padEnd)}`;
  }
  const hints = ['Sometimes you need to read between the lines...', 'What letters are left behind in the wordsearch?'];
  return {
    words,
    usedCells,
    grid: addSecretMessage(cleanGrid, secret),
    paddedXs: amountToPad,
    secretMessage,
    hints,
    foundWords: []
  };
}
export function makeWordSearch(
  secret: string,
  wordList?: string[],
  forceSize?: number
): IWordSearchData {
  const words = wordList || defaultWordList;
  const longestWordLength = words.reduce((acc, word) => (word.length > acc ? word.length : acc), 0);
  const size = forceSize || longestWordLength;
  let bestAttempt: IWordSearchData|null = null;
  let currentAttempt: IWordSearchData|null = null;
  let attempt = RETRY_ATTEMPTS;

  while (attempt > 0 && (!bestAttempt || bestAttempt.paddedXs > ACCEPTABLE_PADDING_ALLOWED)) {
    attempt -= 1;
    currentAttempt = buildWordSearch(words, secret, size);

    if (currentAttempt && (!bestAttempt || currentAttempt.paddedXs < bestAttempt.paddedXs)) {
      bestAttempt = currentAttempt;
    }
  }
  if (bestAttempt) {
    return bestAttempt;
  }
  if (size < 10) {
    return makeWordSearch(secret, words, size + 1);
  }
  throw new Error('Unable to make wordsearch');
}

export function isSwipeValid(start: ICell, end: ICell, words: IWord[]): IWord|null {
  for (let i = 0; i < words.length; i += 1) {
    const word = words[i];
    const wordStart = word.path[0];
    const wordEnd = word.path[word.path.length - 1];
    if (isEqual(start, wordStart) && isEqual(end, wordEnd)) {
      return word;
    }
    if (isEqual(start, wordEnd) && isEqual(end, wordStart)) {
      return { ...word, foundBackwards: true };
    }
  }
  return null;
}

export function getHighlightStyle(startingCell: ICell|null, currentCell: ICell|null, cellSize: number): CSSProperties {
  if (!startingCell || !currentCell) return {};
  return {
    position: 'absolute',
    left: `${startingCell.x * cellSize}px`,
    top: `${startingCell.y * cellSize}px`,
    height: `${cellSize}px`,
    width: `${getHighlightlightWidth(startingCell, currentCell, cellSize)}px`,
    transformOrigin: `${cellSize / 2}px`,
    transform: `rotate(${getHighlightRotation(startingCell, currentCell)}deg)`
  };
}

import { ILocation } from './ILocation';
import { IWordSearchData } from './IWordSearch';

export enum PUZZLE_TYPE {
  WORDSEARCH = 'WORD_SEARCH',
  SAFE = 'SAFE',
  LOCATION_INPUT = 'LOCATION_INPUT',
  CAESER_CIPHER = 'CAESER_CIPHER',
  STRETCHED_TEXT = 'STRETCHED_TEXT',
  CLUE_INPUT = 'CLUE_INPUT'
}

type IPuzzleStepGeneric<T> = {
  onComplete: () => void;
  id: string;
  isComplete: boolean;
  type: PUZZLE_TYPE;
  data: T;
};

export type IWordSearch = IPuzzleStepGeneric<IWordSearchData> & {
  type: PUZZLE_TYPE.WORDSEARCH;
};

export type ISafe = IPuzzleStepGeneric<string> & {
  type: PUZZLE_TYPE.SAFE;
};

export type ILocationInput = IPuzzleStepGeneric<ILocation> & {
  type: PUZZLE_TYPE.LOCATION_INPUT;
};

export type ICaeserCipher = IPuzzleStepGeneric<{
  secretMessage?: string;
  answer: string;
}> & {
  type: PUZZLE_TYPE.CAESER_CIPHER;
};

export type IStretchedText = IPuzzleStepGeneric<{
  secretMessage: string;
  decoy: string;
  answer: string;
}> & {
  type: PUZZLE_TYPE.STRETCHED_TEXT;
};

export type IClueInput = IPuzzleStepGeneric<{
  answer: string;
  clue?: string;
  hints?: string[];
}> & {
  type: PUZZLE_TYPE.CLUE_INPUT;
};

export type IPuzzleStep = IWordSearch | ISafe | ILocationInput | ICaeserCipher | IStretchedText | IClueInput;

export type IQuest = {
  id: string;
  name: string;
  steps: IPuzzleStep[];
};
